/**
 * @file 404 入口
 * @author FengGuang(fengguang01@baidu.com)
 */
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'location-origin';
import React from 'react';
import ReactDOM from 'react-dom';

import PageNotFound from './PageNotFound';

import './style.less';



ReactDOM.render(<PageNotFound />, document.getElementById('root'));


