/**
 * @file 404 页面
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, { useEffect, useState } from 'react';
import { Button } from 'antd';

import Header from '../../components/header/HeaderWithStore';
import Footer from '../../components/footer/footer';

const PageNotFound: React.FC = () => {
    const [time, setTime] = useState(4000);

    useEffect(() => {
        const now = Date.now();
        const intervalHandle = setInterval(() => {
            const newTime = 4000 - (Date.now() - now);
            if (newTime <= 0) {
                clearInterval(intervalHandle);
                setTime(0);
            }
            else {
                setTime(newTime);
            }
        }, 200);
        setTimeout(() => {
            window.location.href = '/';
        }, 4000);

        return () => {
            clearInterval(intervalHandle);
        };
    }, []);

    return (
        <div className="page paddle-404-page">
            <Header />
            <div className="paddle-404-page-container">
                <div className="paddle-404-page-context">
                    <div className="paddle-404-page-img-wrap">
                        <div className="paddle-404-page-img" />
                    </div>
                    <div className="paddle-404-page-title">
                        Oops!
                    </div>
                    <div className="paddle-404-page-subtitle">
                        抱歉，页面丢失啦~ {Math.floor(time / 1000)}秒后跳转到首页
                    </div>
                    <div className="paddle-404-page-btn-group">
                        <a href="/">
                            <Button
                                size="large"
                                type="primary"
                                className="paddle-404-page-btn"
                            >
                                返回首页
                            </Button>
                        </a>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PageNotFound;
